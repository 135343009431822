package com.appcreator.blueprint.revenuecat

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ConditionBuilder
import com.appcreator.blueprint.spec.ConditionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class RevenuecatHasEntitlementConditionBuilder(
  condition: RevenuecatHasEntitlementCondition?,
  newId: Boolean = false,
) : ConditionBuilder {
  override val spec: ConditionSpec = ConditionSpec(
          name = "RevenuecatHasEntitlementCondition",
          title = "Has Entitlement",
          group = "Revenue Cat",
          isExtraPlugin = true,
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
      )

  public val entitlement: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Entitlement",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = false,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(condition?.entitlement),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(entitlement)

  override fun build(): Condition = RevenuecatHasEntitlementCondition(entitlement.value)
}
