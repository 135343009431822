package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.conversions.BasicMathsConversion
import com.appcreator.blueprint.conversions.BasicMathsConversionBuilder
import com.appcreator.blueprint.conversions.DateAdditionConversion
import com.appcreator.blueprint.conversions.DateAdditionConversionBuilder
import com.appcreator.blueprint.conversions.DateFormatConversion
import com.appcreator.blueprint.conversions.DateFormatConversionBuilder
import com.appcreator.blueprint.conversions.JavascriptConversion
import com.appcreator.blueprint.conversions.JavascriptConversionBuilder
import com.appcreator.blueprint.conversions.ListCountConversion
import com.appcreator.blueprint.conversions.ListCountConversionBuilder
import com.appcreator.blueprint.conversions.NowConversion
import com.appcreator.blueprint.conversions.NowConversionBuilder
import com.appcreator.blueprint.conversions.TodayConversion
import com.appcreator.blueprint.conversions.TodayConversionBuilder
import com.appcreator.blueprint.conversions.UUIDConversion
import com.appcreator.blueprint.conversions.UUIDConversionBuilder
import com.appcreator.blueprint.conversions.UppercaseConversion
import com.appcreator.blueprint.conversions.UppercaseConversionBuilder
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingGetTokenConversion
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingGetTokenConversionBuilder
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigConversion
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigConversionBuilder
import com.appcreator.blueprint.spec.ConversionBuilder
import kotlin.Boolean

public fun Conversion.toBuilder(newId: Boolean = false): ConversionBuilder {
  val builder = when(this) {
      is BasicMathsConversion -> BasicMathsConversionBuilder(this, newId)
      is DateAdditionConversion -> DateAdditionConversionBuilder(this, newId)
      is DateFormatConversion -> DateFormatConversionBuilder(this, newId)
      is JavascriptConversion -> JavascriptConversionBuilder(this, newId)
      is ListCountConversion -> ListCountConversionBuilder(this, newId)
      is NowConversion -> NowConversionBuilder(this, newId)
      is TodayConversion -> TodayConversionBuilder(this, newId)
      is UUIDConversion -> UUIDConversionBuilder(this, newId)
      is UppercaseConversion -> UppercaseConversionBuilder(this, newId)
      is FirebaseMessagingGetTokenConversion -> FirebaseMessagingGetTokenConversionBuilder(this,
      newId)
      is FirebaseRemoteConfigConversion -> FirebaseRemoteConfigConversionBuilder(this, newId)
      else -> throw IllegalStateException("Trying to convert $this which has no mapping")
  }
  return builder
}
