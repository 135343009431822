package com.appcreator.blueprint.firebase.messaging

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseMessagingSubscribeToTopicAction")
@ActionClass(
    group = FirebaseMessagingConsts.group,
    title = "Subscribe to topic",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
class FirebaseMessagingSubscribeToTopicAction(
    @InputProperty(
        title = "Topic",
        availableFrom = "0.0.1"
    )
    val topic: String?,
): Action
