package com.appcreator.compose

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Snackbar
import androidx.compose.material3.SnackbarDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.actions.alerts.SnackbarAlertAction
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.compose.extensions.composeColor
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.receiveAsFlow
import kotlin.time.Duration.Companion.seconds


data class SnackbarData(
    val message: String,
    val containerColor: ColorReference?,
    val contentColor: ColorReference?,
    val alignment: SnackbarAlertAction.Alignment?
)

val LocalSnackbarAlert = staticCompositionLocalOf<Channel<SnackbarData>?> {
   null
}

@Composable
expect fun SnackbarAlertContainer(modifier: Modifier = Modifier, content: @Composable () -> Unit)
