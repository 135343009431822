package com.appcreator.blueprint.firebase.messaging

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseMessagingGetTokenConversion")
@ConversionClass(
    title = "Get FCM Token",
    availableFrom = "0.0.1",
    group = FirebaseMessagingConsts.group,
    isExtraPlugin = true
)
class FirebaseMessagingGetTokenConversion: Conversion
