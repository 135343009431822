package com.appcreator.creatorapp.editor

import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Help
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import com.appcreator.styles.ThemeMargins

@Composable
fun DocsButton(type: String, name: String) {

    val uriHandler = LocalUriHandler.current
    TextButton(
        onClick = {
        val linkName = name.lowercase().replace(" ", "-")
        uriHandler.openUri("https://docs.yourappcreator.com/section/$type/_/documentation/$linkName")
    }
    ) {
        Icon(Icons.AutoMirrored.Default.Help, contentDescription = null)
        Spacer(Modifier.width(ThemeMargins.SmallMargin))
        Text("More Info")
    }

}