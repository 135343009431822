package com.appcreator.blueprint.components.`data`

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.BooleanInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.ConditionInputSpec
import com.appcreator.blueprint.spec.inputs.DataPathInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.IntInputSpec
import com.appcreator.blueprint.spec.inputs.LoaderConfigInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class PagedLoaderComponentBuilder(
  component: PagedLoaderComponent?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "Data",
          name = "PagedLoaderComponent",
          title = "Paging Data Loader",
          isExtraPlugin = false,
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  override val _nodeRelativeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeRelativeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(if(component?._nodeRelativeId == null) Uuid.random().toString()
          else component._nodeRelativeId),
          
          
      )

  public val config: LoaderConfigInputSpec = LoaderConfigInputSpec(
          properties = InputProperties(
          section = "",
          title = "Loader",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.config),
          
          
      )

  public val itemsPath: DataPathInputSpec = DataPathInputSpec(
          properties = InputProperties(
          section = "",
          title = "Path",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "List",
          settableDataOnly = false,
          includeSiblingData = true,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.itemsPath),
          
          
      )

  public val condition: ConditionInputSpec = ConditionInputSpec(
          properties = InputProperties(
          section = "",
          title = "Load More Condition",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = true,
          visible = { !false && config.value != null && config.value?.supportsPaging != true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.condition?.toBuilder(newId)),
          
          
      )

  public val `header`: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Header",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.header?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val content: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Component",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.content?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val empty: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Empty",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.empty?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val footer: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Footer",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.footer?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val itemWidth: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "",
          title = "Item Width",
          description =
          "Max width of item, will fit at many items in a grid as possible, leave blank for full width.",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.itemWidth),
          
          
      )

  public val itemSpacing: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "",
          title = "Item Spacing",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.itemSpacing),
          
          
      )

  public val pullToRefresh: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Pull to refresh",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.pullToRefresh),
          
          
      )

  public val errorView: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Error type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.errorView?:
          PagedLoaderComponent.ViewType.Default).name),
          
          options = PagedLoaderComponent.ViewType.entries.map { it.name },
      )

  public val errorContent: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Error content",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && errorView.value == PagedLoaderComponent.ViewType.Custom.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.errorContent?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val loadingView: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Loader type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.loadingView?:
          PagedLoaderComponent.ViewType.Default).name),
          
          options = PagedLoaderComponent.ViewType.entries.map { it.name },
      )

  public val loadingContent: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Loader content",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && loadingView.value == PagedLoaderComponent.ViewType.Custom.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.loadingContent?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val reloadTrigger: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Reload From Trigger",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.reloadTrigger),
          
          
      )

  public val previewDisplay: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Preview",
          title = "Show In Preview",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.previewDisplay?:
          PagedLoaderComponent.PreviewState.Loaded).name),
          
          options = PagedLoaderComponent.PreviewState.entries.map { it.name },
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, _nodeRelativeId, config,
      itemsPath, condition, header, content, empty, footer, itemWidth, itemSpacing, pullToRefresh,
      errorView, errorContent, loadingView, loadingContent, reloadTrigger, previewDisplay)

  override fun build(): Component = PagedLoaderComponent(_nodeId.value?: Uuid.random().toString(),
      _nodeLabel.value, _nodeRelativeId.value, config.value, itemsPath.value,
      condition.value?.build(), header.value?.build(), content.value?.build(), empty.value?.build(),
      footer.value?.build(), itemWidth.value, itemSpacing.value, pullToRefresh.value,
      errorView.value?.let { PagedLoaderComponent.ViewType.valueOf(it) }?:
      PagedLoaderComponent.ViewType.Default, errorContent.value?.build(), loadingView.value?.let {
      PagedLoaderComponent.ViewType.valueOf(it) }?: PagedLoaderComponent.ViewType.Default,
      loadingContent.value?.build(), reloadTrigger.value, previewDisplay.value?.let {
      PagedLoaderComponent.PreviewState.valueOf(it) }?: PagedLoaderComponent.PreviewState.Loaded)
}
