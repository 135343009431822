package com.appcreator.compose.components.data

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.pulltorefresh.rememberPullToRefreshState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.ProvidedValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import androidx.lifecycle.viewmodel.compose.viewModel
import app.cash.paging.COUNT_UNDEFINED
import app.cash.paging.LoadStateError
import app.cash.paging.LoadStateLoading
import app.cash.paging.MAX_SIZE_UNBOUNDED
import app.cash.paging.Pager
import app.cash.paging.PagingConfig
import app.cash.paging.PagingSource
import app.cash.paging.PagingSourceLoadParams
import app.cash.paging.PagingSourceLoadResult
import app.cash.paging.PagingSourceLoadResultError
import app.cash.paging.PagingSourceLoadResultPage
import app.cash.paging.PagingState
import app.cash.paging.cachedIn
import app.cash.paging.compose.LazyPagingItems
import app.cash.paging.compose.collectAsLazyPagingItems
import app.cash.paging.compose.itemKey
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.components.data.PagedLoaderComponent
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.LoaderConfig
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.compose.AbsoluteSize
import com.appcreator.compose.LocalAbsoluteSize
import com.appcreator.compose.LocalBlueprint
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.LocalTriggerBus
import com.appcreator.compose.PlatformPullToRefreshBox
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.conditions.Evaluator
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator
import com.appcreator.compose.loaders.Loader
import com.appcreator.compose.loaders.createPreviewData
import kotlinx.coroutines.delay


private class DataSource(
    private val inPreview: Boolean,
    private val previewDisplay: PagedLoaderComponent.PreviewState?,
    private val envStore: EnvStore,
    private val config: LoaderConfig?,
    loadMore: Condition?,
    private val path: String?,
    blueprint: Blueprint
): PagingSource<EnvStore, EnvStore>() {

    private val spec: LoaderSpec? = config?.let { blueprint.loaderSpec(it.loaderSpec) }
    private val pageLoader: Loader? = spec?.let { Container.loaderRegistry[it::class]?.invoke(it) }
    private val evaluator: Evaluator? = loadMore?.let { Container.evaluator(it) }

    override suspend fun load(
        params: PagingSourceLoadParams<EnvStore>
    ): PagingSourceLoadResult<EnvStore, EnvStore> {
        return try {
            val data:  Pair<ProvidedValue<*>?, Map<String, Any>> = if(inPreview) {
                when(previewDisplay) {
                    PagedLoaderComponent.PreviewState.Loading -> {
                        while (true) { delay(300) }
                        throw IllegalStateException()
                    }
                    PagedLoaderComponent.PreviewState.Error -> throw IllegalStateException()
                    PagedLoaderComponent.PreviewState.Empty -> null to emptyMap()
                    else -> null to (spec?.createPreviewData()?: emptyMap())
                }
            } else pageLoader!!.load(params.key ?: envStore, config!!.parameters, null)

            val envStore = EnvStore.create(parent = envStore, env = data.second)
            val listItems = envStore.envFromList(path ?: "")
            val nextKey = when {
                inPreview -> null
                spec?.supportsPaging == true -> if (pageLoader?.canLoadMore(data.second) == true) envStore else null
                else -> if(evaluator!!.evaluateAsync(envStore)) envStore else null
            }

            PagingSourceLoadResultPage(
                data = listItems,
                prevKey = null, // Only paging forward.
                nextKey = nextKey
            )
        } catch (ex: Throwable) {
            ex.printStackTrace()
            PagingSourceLoadResultError<EnvStore, EnvStore>(ex)
        } as PagingSourceLoadResult<EnvStore, EnvStore>
    }

    override fun getRefreshKey(state: PagingState<EnvStore, EnvStore>): EnvStore? {
        return null
    }
}

class PagerViewMode(
    envStore: EnvStore,
    inPreview: Boolean,
    component: PagedLoaderComponent,
    blueprint: Blueprint
): ViewModel() {

    val pager = Pager(
        config =
        PagingConfig(
            pageSize = 20,
            prefetchDistance = 20,
            enablePlaceholders = false,
            initialLoadSize = 20,
            maxSize = MAX_SIZE_UNBOUNDED,
            jumpThreshold = COUNT_UNDEFINED
        ), initialKey = null
    ) {
        DataSource(
            inPreview = inPreview,
            previewDisplay = component.previewDisplay,
            envStore = envStore,
            config = component.config,
            loadMore = component.condition,
            path = component.itemsPath?.value,
            blueprint = blueprint
        )
    }.flow.cachedIn(viewModelScope)

}

@Composable
fun PagedLoaderComposable(modifier: Modifier, component: PagedLoaderComponent) {

    val envStore = LocalEnvStore.current
    val blueprint = LocalBlueprint.current
    val inPreview = LocalInPreview.current
    val key = component.config?.parameters?.map { envStore.injectVariables(it.value, staticPass = true) }

    val viewModel = viewModel(key = key?.joinToString()) { PagerViewMode(envStore, inPreview, component, blueprint) }

    val pager = viewModel.pager
    val items = pager.collectAsLazyPagingItems()
    val refreshing = items.loadState.refresh == LoadStateLoading
    val pullToRefresh = rememberPullToRefreshState()
    val onRefresh: () -> Unit = {

        items.refresh()
    }

    component.reloadTrigger?.let {
        val bus = LocalTriggerBus.current
        DisposableEffect(component.reloadTrigger) {
            bus.addListener(it) {
                onRefresh()
            }
            onDispose {
                bus.removeListener(it)
            }
        }
    }

    val pullToRefreshEnabled = component.pullToRefresh == true

    val content: @Composable () -> Unit = {
        Box(Modifier.fillMaxSize()) {
            when(items.loadState.refresh) {
                LoadStateLoading -> Loading(modifier, component, pullToRefreshEnabled)
                is LoadStateError -> ErrorView(modifier, component, (items.loadState.refresh as LoadStateError).error) {
                    onRefresh()
                }
                else -> {
                    LoadedContent(modifier, component, items)
                }
            }
        }
    }
    if(pullToRefreshEnabled) {
        PlatformPullToRefreshBox(isRefreshing = refreshing, state = pullToRefresh, onRefresh = onRefresh) {
            content()
        }
    } else {
        content()
    }
}

@Composable
private fun Loading(modifier: Modifier, component: PagedLoaderComponent, pullToRefreshEnabled: Boolean) {
    when(component.loadingView) {
        PagedLoaderComponent.ViewType.Nothing -> {}
        PagedLoaderComponent.ViewType.Custom -> component.loadingContent?.let {
            ComponentComposable(
                modifier,
                it
            )
        }
        else -> {
            if (!pullToRefreshEnabled) {
                DefaultLoadingComposable(modifier)
            }
        }
    }
}

@Composable
private fun ErrorView(
    modifier: Modifier,
    component: PagedLoaderComponent,
    error: Throwable,
    refresh: () -> Unit
) {
    when(component.errorView) {
        PagedLoaderComponent.ViewType.Nothing -> {}
        PagedLoaderComponent.ViewType.Custom -> component.errorContent?.let { ComponentComposable(modifier, it) }
        else -> DefaultErrorComposable(modifier, error, refresh)
    }
}

@Composable
private fun LoadedContent(
    modifier: Modifier,
    component: PagedLoaderComponent,
    items: LazyPagingItems<EnvStore>,
) {
    if(items.itemCount == 0) {
        component.empty?.let {
            ComponentComposable(Modifier, it)
        }
    } else {

        val spacing = (component.itemSpacing?: 0).dp
        LazyVerticalGrid(
            modifier = modifier.fillMaxWidth(),
            contentPadding = PaddingValues(vertical = spacing),
            columns = component.itemWidth?.let {
                if(it > 0) {
                    GridCells.FixedSize(it.dp)
                } else GridCells.Fixed(1)
            }?: GridCells.Fixed(1),
            verticalArrangement = Arrangement.spacedBy(spacing),
            horizontalArrangement = Arrangement.spacedBy(spacing, Alignment.CenterHorizontally)
        ) {

            component.header?.let {
                item(span = { GridItemSpan(maxCurrentLineSpan) }) {
                    BoxWithConstraints {
                        CompositionLocalProvider(LocalAbsoluteSize provides AbsoluteSize(this)) {
                            ComponentComposable(Modifier, it)
                        }
                    }
                }
            }

            items(items.itemCount, key = items.itemKey()) {
                items[it]?.let { item ->
                    CompositionLocalProvider(LocalEnvStore provides item) {
                        component.content?.let {
                            ComponentComposable(Modifier, it)
                        }
                    }
                }
            }

            when (items.loadState.append) {
                is LoadStateLoading -> item(span = { GridItemSpan(maxCurrentLineSpan) }) {
                    Box(
                        Modifier.fillMaxWidth().padding(8.dp),
                        contentAlignment = Alignment.Center
                    ) {
                        CircularProgressIndicator()
                    }
                }

                else -> {
                    component.footer?.let {
                        item(span = { GridItemSpan(maxCurrentLineSpan) }) {
                            BoxWithConstraints {
                                CompositionLocalProvider(LocalAbsoluteSize provides AbsoluteSize(this)) {
                                    ComponentComposable(Modifier, it)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}