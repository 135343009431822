package com.appcreator.compose.actions

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.DelayAction
import com.appcreator.blueprint.core.EnvStore
import kotlinx.coroutines.delay

class DelayPerformer(private val action: DelayAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                envStore.injectVariables(action.duration?: "").toLongOrNull()?.let {
                    delay(it)
                }
            }
        }
    }
}
