package com.appcreator.creatorapp.project.configurations

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.appcreator.app.file.openFilePicker
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.SnackbarInfo
import com.appcreator.shared.api.FileUploadInfo
import com.appcreator.styles.ThemeMargins
import io.ktor.utils.io.ByteReadChannel
import kotlinx.coroutines.launch

@Composable
fun IOSConfiguration(organisationResponse: OrganisationResponse, project: Project) {
    LoadingComposable(loader = {
        mutableStateOf(it.getIOSConfiguration(organisationResponse.organisation.id, project.id))
    }) {

        val client = LocalApiClient.current
        var display by remember { mutableStateOf(it.value) }

        Column(Modifier.padding(ThemeMargins.LargeMargins)) {


            val urlHandler = LocalUriHandler.current
            TextButton(onClick = {
                urlHandler.openUri("https://docs.yourappcreator.com/guide-details/how-to-set-up-your-ios-app")
            }) {
                Text("View iOS setup guide")
            }
            Spacer(Modifier.height(ThemeMargins.SmallMargin))

            Text(
                style = MaterialTheme.typography.bodyMedium,
                text = "A bundle ID or bundle identifier uniquely identifies an application in Apple's ecosystem. This means that no two applications can have the same bundle identifier. To avoid conflicts, Apple encourages developers to use reverse domain name notation for choosing an application's bundle identifier."
            )
            Spacer(Modifier.height(ThemeMargins.SmallMargin))
            Text(
                style = MaterialTheme.typography.bodyMedium,
                text = "It must only include letters, separated into 3 sections with dots."
            )
            Spacer(Modifier.height(ThemeMargins.MedMargins))

            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Bundle Identifier",
                value = display.bundleIdentifier ?: "",
                onValueChange = {
                    display = display.copy(bundleIdentifier = it.trim())
                },
                supportingText = "Example: com.example.myapp"
            )

            Spacer(Modifier.height(ThemeMargins.MedMargins))
            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Team ID",
                value = display.teamId ?: "",
                onValueChange = {
                    display = display.copy(teamId = it)
                }
            )

            Spacer(Modifier.height(ThemeMargins.SmallMargin))

            Column(
                Modifier.border(
                    1.dp,
                    color = MaterialTheme.colorScheme.onBackground,
                    shape = RoundedCornerShape(8.dp)
                ).padding(ThemeMargins.SmallMargin)
            ) {

                UploadP8Button(organisationResponse, project, display.p8File) {
                    display = display.copy(p8File = true)
                }

                Spacer(Modifier.height(ThemeMargins.SmallMargin))
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Key ID",
                    value = display.keyId ?: "",
                    onValueChange = {
                        display = display.copy(keyId = it)
                    }
                )
                Spacer(Modifier.height(ThemeMargins.SmallMargin))
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Issuer ID",
                    value = display.issuerId ?: "",
                    onValueChange = {
                        display = display.copy(issuerId = it)
                    }
                )
            }

            Spacer(Modifier.height(ThemeMargins.SmallMargin))
            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "AppStore App ID",
                value = display.appStoreAppId ?: "",
                onValueChange = {
                    display = display.copy(appStoreAppId = it)
                }
            )

            Spacer(Modifier.height(ThemeMargins.LargeMargins))

            LoadingButton(
                label = "Save Changes",
                enabled = it.value != display,
                action = {
                    client.setIOSConfiguration(
                        organisationResponse.organisation.id,
                        project.id,
                        display
                    )
                    it.value = display
                }
            )
        }
    }
}

@Composable
fun LoadingButton(
    modifier: Modifier = Modifier,
    label: String,
    enabled: Boolean = true,
    action: suspend () -> Unit
) {
    var loading by remember { mutableStateOf(false) }
    val snackChannel = LocalSnackbar.current
    val scope = rememberCoroutineScope()
    Button(
        modifier = modifier,
        enabled = enabled && !loading,
        onClick = {
            scope.launch {
                loading = true
                try {
                    action()
                } catch (ex: Exception) {
                    ex.printStackTrace()
                    snackChannel.send(SnackbarInfo.Builder.fromNetworkException(ex))
                }
                loading = false
            }
        }) {
        AnimatedContent(loading) { showing ->
            if (showing) {
                CircularProgressIndicator(
                    strokeWidth = 2.dp,
                    modifier = Modifier.size(24.dp)
                )
            } else {
                Text(label)
            }
        }
    }
}


@Composable
private fun UploadP8Button(
    organisationResponse: OrganisationResponse,
    project: Project,
    hasP8: Boolean,
    onSuccess: () -> Unit
) {
    var loading by remember { mutableStateOf(false) }
    val scope = rememberCoroutineScope()
    val client = LocalApiClient.current
    val snackbarChannel = LocalSnackbar.current
    Button(
        enabled = !loading,
        onClick = {
            openFilePicker(".p8") {
                scope.launch {
                    loading = true
                    try {
                        client.uploadP8(organisationResponse.organisation.id, project.id, FileUploadInfo(
                            contentType = it.mimeType,
                            size = it.size,
                            file = ByteReadChannel(it.data),
                            filename = it.name
                        ))
                        onSuccess()
                    } catch (ex: Exception) {
                        ex.printStackTrace()
                        snackbarChannel.send(SnackbarInfo.Builder.fromNetworkException(ex))
                    }
                    loading = false
                }
            }
        }
    ) {
        AnimatedContent(loading) { showing ->
            if (showing) {
                CircularProgressIndicator(
                    strokeWidth = 2.dp,
                    modifier = Modifier.size(24.dp)
                )
            } else {
                Text(if (hasP8) { "Replace .p8 file" } else "Upload .p8 file")
            }
        }
    }

}