package com.appcreator.compose.components.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ProvidedValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.data.LoadingComponent

internal sealed class LoadingState {
    data class Loading(val requiresIndicator: Boolean): LoadingState()
    data class Loaded(val providedValue: ProvidedValue<*>?, val env: Map<String, Any>): LoadingState()
    data class Error(val ex: Exception): LoadingState()
}

@Composable
expect fun LoadingComposable(modifier: Modifier, component: LoadingComponent)
