package com.appcreator.compose.conditions.logic

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.conditions.logic.NotCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.conditions.Evaluator
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator

class NotEvaluator(private val condition: NotCondition): Evaluator {

    @Composable
    override fun evaluate(envStore: EnvStore): Boolean {
        return condition.notCondition?.let {
            val result = Container.evaluator(it)?.evaluate(envStore) == true
            if (condition.enabled == true) result.not() else result
        }?: false
    }
    override suspend fun evaluateAsync(envStore: EnvStore): Boolean {
        return condition.notCondition?.let {
            val result = Container.evaluator(it)?.evaluateAsync(envStore) == true
            if (condition.enabled == true) result.not() else result
        }?: false
    }

    override fun evaluateOnce(envStore: EnvStore): Boolean? {
        return condition.notCondition?.let {
            val result = Container.evaluator(it)?.evaluateOnce(envStore) == true
            if (condition.enabled == true) result.not() else result
        }?: false
    }

}