package com.appcreator.blueprint.conditions

import com.appcreator.blueprint.conditions.JavascriptCondition
import com.appcreator.blueprint.conditions.JavascriptConditionBuilder
import com.appcreator.blueprint.conditions.`data`.ContainsCondition
import com.appcreator.blueprint.conditions.`data`.ContainsConditionBuilder
import com.appcreator.blueprint.conditions.`data`.ContainsItemCondition
import com.appcreator.blueprint.conditions.`data`.ContainsItemConditionBuilder
import com.appcreator.blueprint.conditions.`data`.DateCondition
import com.appcreator.blueprint.conditions.`data`.DateConditionBuilder
import com.appcreator.blueprint.conditions.`data`.EqualsCondition
import com.appcreator.blueprint.conditions.`data`.EqualsConditionBuilder
import com.appcreator.blueprint.conditions.`data`.NumberCompareCondition
import com.appcreator.blueprint.conditions.`data`.NumberCompareConditionBuilder
import com.appcreator.blueprint.conditions.logic.AndCondition
import com.appcreator.blueprint.conditions.logic.AndConditionBuilder
import com.appcreator.blueprint.conditions.logic.NotCondition
import com.appcreator.blueprint.conditions.logic.NotConditionBuilder
import com.appcreator.blueprint.conditions.logic.OrCondition
import com.appcreator.blueprint.conditions.logic.OrConditionBuilder
import com.appcreator.blueprint.conditions.permission.CheckPermissionCondition
import com.appcreator.blueprint.conditions.permission.CheckPermissionConditionBuilder
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoggedInCondition
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoggedInConditionBuilder
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigCondition
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigConditionBuilder
import com.appcreator.blueprint.revenuecat.RevenuecatHasEntitlementCondition
import com.appcreator.blueprint.revenuecat.RevenuecatHasEntitlementConditionBuilder
import com.appcreator.blueprint.spec.ConditionBuilder
import kotlin.Boolean

public fun Condition.toBuilder(newId: Boolean = false): ConditionBuilder {
  val builder = when(this) {
      is JavascriptCondition -> JavascriptConditionBuilder(this, newId)
      is ContainsCondition -> ContainsConditionBuilder(this, newId)
      is ContainsItemCondition -> ContainsItemConditionBuilder(this, newId)
      is DateCondition -> DateConditionBuilder(this, newId)
      is EqualsCondition -> EqualsConditionBuilder(this, newId)
      is NumberCompareCondition -> NumberCompareConditionBuilder(this, newId)
      is AndCondition -> AndConditionBuilder(this, newId)
      is NotCondition -> NotConditionBuilder(this, newId)
      is OrCondition -> OrConditionBuilder(this, newId)
      is CheckPermissionCondition -> CheckPermissionConditionBuilder(this, newId)
      is FirebaseAuthLoggedInCondition -> FirebaseAuthLoggedInConditionBuilder(this, newId)
      is FirebaseRemoteConfigCondition -> FirebaseRemoteConfigConditionBuilder(this, newId)
      is RevenuecatHasEntitlementCondition -> RevenuecatHasEntitlementConditionBuilder(this, newId)
      else -> throw IllegalStateException("Trying to convert $this which has no mapping")
  }
  return builder
}
