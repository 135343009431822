package com.appcreator.creatorapp.project.configurations

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.TestOnly
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.appcreator.app.file.openTextFilePicker
import com.appcreator.blueprint.firebase.auth.FirebaseAuthConsts
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreConsts
import com.appcreator.blueprint.firebase.installations.FirebaseInstallationsConsts
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingConsts
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigConsts
import com.appcreator.blueprint.firebase.storage.FirebaseStorageConsts
import com.appcreator.components.layouts.CheckboxGroup
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.ScrollBars
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.Organisation
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.dto.configurations.FirebaseConfiguration
import com.appcreator.dto.configurations.FirebaseWebConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfigurationTypes
import com.appcreator.styles.ThemeMargins
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonObject


private fun ThirdPartyConfiguration.configOrDefault(json: Json): FirebaseConfiguration {
    return config?.let { json.decodeFromJsonElement(it) } ?: FirebaseConfiguration(
        androidNotificationsEnabled = false,
        iosNotificationsEnabled = false,
        inAppMessaging = false,
        analyticsEnabled = false,
        authenticationEnabled = false,
        firestoreEnabled = false,
        remoteConfigEnabled = false,
        storageEnabled = false,
        installationsEnabled = false,
        androidServiceJson = null,
        iosServicePlist = null,
        webConfig = FirebaseWebConfiguration(
            apiKey = "",
            authDomain = "",
            projectId = "",
            storageBucket = "",
            messagingSenderId = "",
            appId = "",
            measurementId = "",
            hostingSite = null
        )
    )
}

@Composable
fun FirebaseConfiguration(
    organisation: Organisation,
    project: Project,
    inset: Dp = ThemeMargins.LargeMargins,
    showOptions: Boolean = true,
    forceEnableAuth: Boolean = false,
    forceEnableFirestore: Boolean = false,
    saveAtTop: Boolean = true,
    webOnly: Boolean = false,
    onSave: () -> Unit = {}
) {

    LoadingComposable(loader = {
        mutableStateOf(it.getThirdPartyConfiguration(organisation.id, project.id, ThirdPartyConfigurationTypes.firebase))
    }) {

        val json = LocalApiClient.current.json
        var display by remember { mutableStateOf(it.value) }
        val startConfig = remember { it.value.configOrDefault(json) }
        var displayConfig by remember { mutableStateOf(startConfig) }

        val updateGroups: (String, Boolean) -> Unit = { group, enabled ->
            display = display.copy(
                components = if (enabled) display.components.plus(
                    group
                ) else display
                    .components.toSet()
                    .minus(group)
                    .toList()
            )
        }

        val enableAuth: (Boolean) -> Unit = {
            displayConfig = displayConfig.copy(authenticationEnabled = it)
            val authProperties = setOf(FirebaseAuthConsts.uid, FirebaseAuthConsts.userDisplayName, FirebaseAuthConsts.userToken)
            val global = display.globalState?: emptySet()
            updateGroups(FirebaseAuthConsts.group, it)
            display = display.copy(
                globalState = if(it) global.plus(authProperties) else global.minus(authProperties),
            )
        }

        val enableFirestore: (Boolean) -> Unit = {
            displayConfig = displayConfig.copy(firestoreEnabled = it)
            updateGroups(FirebaseFirestoreConsts.group, it)
        }

        val save: @Composable () -> Unit = {
            val client = LocalApiClient.current
            LoadingButton(
                label = "Save Changes",
                enabled = it.value != display || startConfig != displayConfig,
                action = {
                    client.setThirdPartyConfiguration(
                        organisation.id,
                        project.id,
                        display.copy(config = json.encodeToJsonElement(displayConfig).jsonObject)
                    )
                    it.value = display
                    onSave()
                }
            )
    }

        val forceEnabled = forceEnableAuth || forceEnableAuth
        LaunchedEffect(forceEnabled) {
            if (forceEnabled) {
                if(display.enabled) {
                    // Has been set up before
                    onSave()
                }
                display = display.copy(enabled = true)
                if (forceEnableAuth) {
                    enableAuth(true)
                }
                if(forceEnableFirestore) {
                    enableFirestore(true)
                }
            }
        }

        Column(Modifier.padding(horizontal = inset)) {
            if(!forceEnabled) {
                CheckboxGroup(
                    label = "Enabled",
                    checked = display.enabled,
                    onCheckedChange = {
                        display = display.copy(enabled = it)
                    }
                )
            }

            if(saveAtTop) {
                save()
            }
        }

        Box {
            Column(Modifier.padding(horizontal = inset)) {

                if(showOptions) {
                    val uriHandler = LocalUriHandler.current
                    TextButton(onClick = {
                        uriHandler.openUri("https://docs.yourappcreator.com/guide-details/how-to-add-firebase-to-your-app")
                    }) {
                        Text("View Setup Guide")
                    }
                }

                Spacer(Modifier.height(ThemeMargins.LargeMargins))

                if (display.enabled) {

                    if(showOptions) {

                        Text(
                            text = "Services",
                            style = MaterialTheme.typography.headlineSmall
                        )

                        Text(
                            text = "Only enable service you are using, each service can increase the download size of your app.",
                            style = MaterialTheme.typography.bodySmall
                        )

                        Spacer(Modifier.height(ThemeMargins.SmallMargin))

                        CheckboxGroup(
                            label = "Android Notifications",
                            checked = displayConfig.androidNotificationsEnabled,
                            onCheckedChange = {
                                displayConfig = displayConfig.copy(androidNotificationsEnabled = it)
                            }
                        )
                        CheckboxGroup(
                            label = "iOS Notifications",
                            checked = displayConfig.iosNotificationsEnabled,
                            onCheckedChange = {
                                displayConfig = displayConfig.copy(iosNotificationsEnabled = it)
                            }
                        )
                        CheckboxGroup(
                            label = "In App Messaging",
                            checked = displayConfig.inAppMessaging,
                            onCheckedChange = {
                                displayConfig = displayConfig.copy(inAppMessaging = it)
                                updateGroups(FirebaseMessagingConsts.group, it)
                            }
                        )
                        CheckboxGroup(
                            label = "Analytics",
                            checked = displayConfig.analyticsEnabled,
                            onCheckedChange = {
                                displayConfig = displayConfig.copy(analyticsEnabled = it)
                            }
                        )
                        CheckboxGroup(
                            label = "Authentication",
                            checked = displayConfig.authenticationEnabled,
                            onCheckedChange = enableAuth
                        )
                        CheckboxGroup(
                            label = "Firestore",
                            checked = displayConfig.firestoreEnabled,
                            onCheckedChange = enableFirestore
                        )
                        CheckboxGroup(
                            label = "Remote Config",
                            checked = displayConfig.remoteConfigEnabled == true,
                            onCheckedChange = {
                                displayConfig = displayConfig.copy(remoteConfigEnabled = it)
                                updateGroups(FirebaseRemoteConfigConsts.group, it)
                            }
                        )
                        CheckboxGroup(
                            label = "Storage Config",
                            checked = displayConfig.storageEnabled == true,
                            onCheckedChange = {
                                displayConfig = displayConfig.copy(storageEnabled = it)
                                updateGroups(FirebaseStorageConsts.group, it)
                            }
                        )
                        CheckboxGroup(
                            label = "Installations",
                            checked = displayConfig.installationsEnabled == true,
                            onCheckedChange = {
                                val authProperties = setOf(FirebaseInstallationsConsts.installationID)
                                displayConfig = displayConfig.copy(installationsEnabled = it)
                                val global = display.globalState?: emptySet()

                                updateGroups(FirebaseInstallationsConsts.group, it)
                                display = display.copy(
                                    globalState = if(it) global.plus(authProperties) else global.minus(authProperties),
                                )
                            }
                        )
                    }

                    if(!webOnly) {
                        TextUploadSection(
                            title = "Android Config",
                            label = "google-services.json",
                            extension = ".json",
                            value = displayConfig.androidServiceJson,
                            update = {
                                displayConfig = displayConfig.copy(androidServiceJson = it)
                            },
                            button = "Upload google-services.json"
                        )
                        TextUploadSection(
                            title = "iOS Config",
                            label = "GoogleServices-info.plist",
                            extension = ".plist",
                            value = displayConfig.iosServicePlist,
                            update = {
                                displayConfig = displayConfig.copy(iosServicePlist = it)
                            },
                            button = "Upload GoogleServices-info.plist"
                        )
                    }

                    WebConfigSection(
                        webConfiguration = displayConfig.webConfig,
                        includeHosting = !webOnly,
                        update = {
                            displayConfig = displayConfig.copy(webConfig = it)
                        }
                    )

                    if(!saveAtTop) {
                        Spacer(Modifier.height(ThemeMargins.LargeMargins))
                        save()
                    }

                    Spacer(Modifier.height(60.dp))
                }
            }
        }
    }

}

@Composable
private fun TextUploadSection(
    title: String,
    label: String,
    extension: String,
    value: String?,
    update: (String?) -> Unit,
    button: String
) {
    Spacer(Modifier.height(ThemeMargins.LargeMargins))
    Text(
        text = title,
        style = MaterialTheme.typography.headlineSmall
    )
    Spacer(Modifier.height(ThemeMargins.SmallMargin))

    value?.let {
        CreatorTextInput(
            modifier = Modifier.height(200.dp).fillMaxWidth(),
            readOnly = true,
            label = label,
            value = it,
            onValueChange = {},
            trailingIcon = {
                IconButton(onClick = {
                    update(null)
                }) {
                    Icon(Icons.Default.Delete, contentDescription = null)
                }
            }
        )
    } ?: run {
        Button(
            onClick = {
                openTextFilePicker(extension) {
                    update(it)
                }
            }) {
            Text(button)
        }
    }
}

@Composable
private fun WebConfigSection(
    webConfiguration: FirebaseWebConfiguration,
    includeHosting: Boolean,
    update: (FirebaseWebConfiguration) -> Unit
) {
    Spacer(Modifier.height(ThemeMargins.LargeMargins))
    Text(
        text = "Web Config",
        style = MaterialTheme.typography.headlineSmall
    )
    Text(
        text = "This configuration is required to make firebase services work in the builder preview",
        style = MaterialTheme.typography.bodySmall
    )
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Api Key",
        value = webConfiguration.apiKey,
        onValueChange = {
            update(webConfiguration.copy(apiKey = it))
        }
    )
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Auth Domain",
        value = webConfiguration.authDomain,
        onValueChange = {
            update(webConfiguration.copy(authDomain = it))
        }
    )
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Project ID",
        value = webConfiguration.projectId,
        onValueChange = {
            update(webConfiguration.copy(projectId = it))
        }
    )
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Storage Bucket",
        value = webConfiguration.storageBucket,
        onValueChange = {
            update(webConfiguration.copy(storageBucket = it))
        }
    )
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Messaging Sender ID",
        value = webConfiguration.messagingSenderId,
        onValueChange = {
            update(webConfiguration.copy(messagingSenderId = it))
        }
    )
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "App ID",
        value = webConfiguration.appId,
        onValueChange = {
            update(webConfiguration.copy(appId = it))
        }
    )
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Measurement ID",
        value = webConfiguration.measurementId,
        onValueChange = {
            update(webConfiguration.copy(measurementId = it))
        }
    )

    if(includeHosting) {

        Spacer(Modifier.height(ThemeMargins.MedMargins))

        CreatorTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Hosting site ID",
            value = webConfiguration.hostingSite ?: "",
            supportingText = "Add this value to have firebase.json automatically added",
            onValueChange = {
                update(webConfiguration.copy(hostingSite = it.ifBlank { null }))
            }
        )
    }
}