package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Colorize
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.properties.ColorValue
import com.appcreator.blueprint.spec.inputs.ColorReferenceInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.components.layouts.Modal
import com.appcreator.components.layouts.ModalSize
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.toColor
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.styles.ThemeMargins
import com.github.skydoves.colorpicker.compose.AlphaSlider
import com.github.skydoves.colorpicker.compose.AlphaTile
import com.github.skydoves.colorpicker.compose.BrightnessSlider
import com.github.skydoves.colorpicker.compose.ColorEnvelope
import com.github.skydoves.colorpicker.compose.HsvColorPicker
import com.github.skydoves.colorpicker.compose.rememberColorPickerController


@Composable
fun ColorInput(spec: ColorReferenceInputSpec, save: () -> Unit) {
    val blueprintManager = LocalBlueprintManager.current

    val name = blueprintManager.theme.colors.find { it.id == spec.value?.id }?.name?: spec.value?.custom?.name?: "Default"
    DropDownInputGroup(
        title = spec.properties.title,
        leadingIcon = blueprintManager.blueprint.theme.color(spec.value)?.let {
                {
                    Box(
                        Modifier
                            .background(it.composeColor(), shape = RoundedCornerShape(4.dp))
                            .size(30.dp)
                            .border(
                                1.dp,
                                color = MaterialTheme.colorScheme.onBackground,
                                shape = RoundedCornerShape(4.dp)
                            )
                    )
                }
            },
        dropDownTitle = name,
        dropDownContent = { close ->
            DropdownItem("Default") {
                spec.value = null
                save()
                close()
            }
            DropdownItem("Custom") {
                spec.value = ColorReference(null, ColorValue(
                    id = "Custom",
                    name = "Custom",
                    baseHex = "#000000",
                    themeValues = emptyMap(),
                    userCreated = true
                )
                )
                save()
                close()
            }

            HorizontalDivider()
            blueprintManager.theme.colors.forEach {
                DropdownItem(it.name) {
                    spec.value = ColorReference(it.id, null)
                    save()
                    close()
                }
            }
        }
    )

    spec.value?.custom?.let {
        ColorConfig(
            title = "Custom",
            color = it
        ) { new ->
            spec.value = ColorReference(null, new)
            save()
        }
    }
}

@Composable
fun ColorConfig(
    title: String = "Color",
    color: ColorValue,
    update: (ColorValue) -> Unit
) {

    var hexValue by remember(color) { mutableStateOf(color.baseHex) }
    CreatorTextInput(
        label = title,
        value = hexValue,
        onValueChange = {
            hexValue = it
        },
        trailingIcon = {
            Modal(
                size = ModalSize.Custom(Modifier.width(width = 500.dp)),
                anchor = {
                    IconButton(onClick = it) {
                        Icon(Icons.Default.Colorize, contentDescription = null)
                    }
                },
                content = { close ->
                    val controller = rememberColorPickerController()
                    val startColor = color.composeColor()
                    LaunchedEffect(startColor) {
                        controller.wheelColor = startColor
                    }
                    var pickerHex by remember { mutableStateOf("") }

                    Column(
                        modifier = Modifier.width(500.dp).padding(ThemeMargins.MedMargins),
                        verticalArrangement = Arrangement.Center,
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        HsvColorPicker(
                            modifier = Modifier
                                .fillMaxWidth()
                                .height(450.dp)
                                .padding(10.dp),
                            controller = controller,
                            onColorChanged = { colorEnvelope: ColorEnvelope ->
                                pickerHex = "#${colorEnvelope.hexCode}"
                            }
                        )

                        AlphaSlider(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(10.dp)
                                .height(35.dp),
                            controller = controller,
                        )

                        BrightnessSlider(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(10.dp)
                                .height(35.dp),
                            controller = controller,
                        )


                        Text(
                            text = pickerHex,
                        )

                        AlphaTile(
                            modifier = Modifier
                                .size(80.dp)
                                .clip(RoundedCornerShape(6.dp)),
                            controller = controller
                        )
                    }
                    Row(modifier = Modifier.fillMaxWidth().padding(ThemeMargins.SmallMargin), horizontalArrangement = Arrangement.End) {
                        TextButton(onClick = {
                            close()
                        }) {
                            Text("Cancel")
                        }
                        TextButton(onClick = {
                            hexValue = pickerHex
                            close()
                        }) {
                            Text("OK")
                        }
                    }
                }
            )
        }
    )

    LaunchedEffect(hexValue) {
//        if (isValidHexCode(hexValue)) {
            if(hexValue != color.baseHex) {
                update(color.copy(baseHex = hexValue))
            }
//        } else {
//            println("Invalid code: $hexValue")
//        }
    }
}

fun isValidHexCode(str: String): Boolean {
    try {
        str.toColor()
        return true
    } catch (ex: Exception) {
        return false
    }
}