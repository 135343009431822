package com.appcreator.blueprint.components.basic

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.blueprint.spec.inputs.BooleanInputSpec
import com.appcreator.blueprint.spec.inputs.ColorReferenceInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentListInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentPositionListInputSpec
import com.appcreator.blueprint.spec.inputs.CornerValuesInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.FloatInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.IntInputSpec
import com.appcreator.blueprint.spec.inputs.LocalizableStringInputSpec
import com.appcreator.blueprint.spec.inputs.SideValuesInputSpec
import com.appcreator.blueprint.spec.inputs.SizeValueInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import com.appcreator.blueprint.spec.inputs.TemplateInfoInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class ContainerComponentBuilder(
  component: ContainerComponent?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "Basic",
          name = "ContainerComponent",
          title = "Container",
          isExtraPlugin = false,
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  override val _nodeRelativeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeRelativeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(if(component?._nodeRelativeId == null) Uuid.random().toString()
          else component._nodeRelativeId),
          
          
      )

  public val content: ComponentListInputSpec = ComponentListInputSpec(
          properties = InputProperties(
          section = "",
          title = "Components",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateListOf(*(component?.content?.map { it.toBuilder(newId) }?:
          emptyList()).toTypedArray()),
          
          
      )

  public val positioning: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Positioning",
          description =
          "Exact allows you to position children freeform this approach is flexible but can make it difficult to make layouts work well across different screen sizes, Relative lets you position children relative to each other with alignment and spacing",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.positioning?:
          ContainerComponent.Positioning.Relative).name),
          
          options = ContainerComponent.Positioning.entries.map { it.name },
      )

  public val exactPositions: ComponentPositionListInputSpec = ComponentPositionListInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Positions",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && positioning.value == ContainerComponent.Positioning.Exact.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateListOf(*(component?.exactPositions?: emptyList()).toTypedArray()),
          
          
      )

  public val axis: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Axis",
          description =
          "X (Row) Align children left to right, Y (Column) Align children top to bottom, X Stack children on top of each other",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && positioning.value != ContainerComponent.Positioning.Exact.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.axis?: ContainerComponent.Axis.Y).name),
          
          options = ContainerComponent.Axis.entries.map { it.name },
      )

  public val width: SizeValueInputSpec = SizeValueInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Width",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.width),
          
          
      )

  public val height: SizeValueInputSpec = SizeValueInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Height",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.height),
          
          
      )

  public val layoutPriority: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Layout Priority",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && axis.value == ContainerComponent.Axis.X.name && overflow.value !=
          true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.layoutPriority?:
          ContainerComponent.LayoutPriority.LeftToRight).name),
          
          options = ContainerComponent.LayoutPriority.entries.map { it.name },
      )

  public val scrolling: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Scrollable",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && positioning.value != ContainerComponent.Positioning.Exact.name &&
          axis.value != ContainerComponent.Axis.Z.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.scrolling?: false),
          
          
      )

  public val keepOffScreenViewsAlive: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Keep offscreen views alive",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && scrolling.value == true && positioning.value !=
          ContainerComponent.Positioning.Exact.name && axis.value != ContainerComponent.Axis.Z.name
          && overflow.value != true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.keepOffScreenViewsAlive?: false),
          
          
      )

  public val padding: SideValuesInputSpec = SideValuesInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Padding",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.padding),
          
          
      )

  public val margin: SideValuesInputSpec = SideValuesInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Margin",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.margin),
          
          
      )

  public val horizontalAlignment: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Alignment",
          title = "Horizontal",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && positioning.value != ContainerComponent.Positioning.Exact.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.horizontalAlignment?:
          ContainerComponent.HorizontalAlignment.Start).name),
          
          options = ContainerComponent.HorizontalAlignment.entries.map { it.name },
      )

  public val verticalAlignment: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Alignment",
          title = "Vertical",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && positioning.value != ContainerComponent.Positioning.Exact.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.verticalAlignment?:
          ContainerComponent.VerticalAlignment.Top).name),
          
          options = ContainerComponent.VerticalAlignment.entries.map { it.name },
      )

  public val spacing: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Alignment",
          title = "Spacing",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && positioning.value != ContainerComponent.Positioning.Exact.name &&
          axis.value != ContainerComponent.Axis.Z.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.spacing?: ContainerComponent.Spacing.None).name),
          
          options = ContainerComponent.Spacing.entries.map { it.name },
      )

  public val spacingValue: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "Alignment",
          title = "Space by",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && positioning.value != ContainerComponent.Positioning.Exact.name &&
          axis.value != ContainerComponent.Axis.Z.name && spacing.value ==
          ContainerComponent.Spacing.ByValue.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.spacingValue),
          
          
      )

  public val backgroundType: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Background",
          title = "Background",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.backgroundType?:
          ContainerComponent.Background.None).name),
          
          options = ContainerComponent.Background.entries.map { it.name },
      )

  public val backgroundColor: ColorReferenceInputSpec = ColorReferenceInputSpec(
          properties = InputProperties(
          section = "Background",
          title = "Color",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && backgroundType.value == ContainerComponent.Background.Color.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.backgroundColor),
          
          
      )

  public val gradient: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Background",
          title = "Apply gradient",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && backgroundType.value == ContainerComponent.Background.Color.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.gradient),
          
          
      )

  public val backgroundImage: LocalizableStringInputSpec = LocalizableStringInputSpec(
          properties = InputProperties(
          section = "Background",
          title = "Image",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && backgroundType.value == ContainerComponent.Background.Image.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.backgroundImage),
          
          
      )

  public val opacity: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "Background",
          title = "Opacity",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && backgroundType.value != ContainerComponent.Background.None.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.opacity),
          
          
      )

  public val borderColor: ColorReferenceInputSpec = ColorReferenceInputSpec(
          properties = InputProperties(
          section = "Border",
          title = "Color",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.borderColor),
          
          
      )

  public val borderRadius: CornerValuesInputSpec = CornerValuesInputSpec(
          properties = InputProperties(
          section = "Border",
          title = "Radius",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.borderRadius),
          
          
      )

  public val borderThickness: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "Border",
          title = "Thickness",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.borderThickness),
          
          
      )

  public val shimmer: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Effects",
          title = "Shimmer",
          description = "Loading effect",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.shimmer),
          
          
      )

  public val viewOpacity: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "Effects",
          title = "Opacity",
          description = "Makes the container see through, value should be between 0 and 1",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.viewOpacity),
          
          
      )

  public val action: ActionInputSpec = ActionInputSpec(
          properties = InputProperties(
          section = "On click",
          title = "Action",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.action?.toBuilder(newId)),
          
          
      )

  public val analyticsButtonName: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "On Click",
          title = "Analytics Name",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && action.value != null },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.analyticsButtonName),
          
          
      )

  public val minWidth: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "Layout Advanced",
          title = "Min Width",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = false,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.minWidth?: 40),
          
          
      )

  public val minHeight: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "Layout Advanced",
          title = "Min Height",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = false,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.minHeight?: 40),
          
          
      )

  public val maxWidth: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "Layout Advanced",
          title = "Max Width",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = false,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.maxWidth),
          
          
      )

  public val maxHeight: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "Layout Advanced",
          title = "Max Height",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = false,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.maxHeight),
          
          
      )

  public val aspectRatio: FloatInputSpec = FloatInputSpec(
          properties = InputProperties(
          section = "Layout Advanced",
          title = "Aspect Ratio",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = false,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.aspectRatio),
          
          
      )

  public val overflow: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Layout Advanced",
          title = "Over flow",
          description =
          "Move items onto a new line if there is not enough space in the selected Axis",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = false,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && axis.value != ContainerComponent.Axis.Z.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.overflow),
          
          
      )

  public val template: TemplateInfoInputSpec = TemplateInfoInputSpec(
          properties = InputProperties(
          section = "Admin",
          title = "Template",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.template),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, _nodeRelativeId, content,
      positioning, exactPositions, axis, width, height, layoutPriority, scrolling,
      keepOffScreenViewsAlive, padding, margin, horizontalAlignment, verticalAlignment, spacing,
      spacingValue, backgroundType, backgroundColor, gradient, backgroundImage, opacity,
      borderColor, borderRadius, borderThickness, shimmer, viewOpacity, action, analyticsButtonName,
      minWidth, minHeight, maxWidth, maxHeight, aspectRatio, overflow, template)

  override fun build(): Component = ContainerComponent(_nodeId.value?: Uuid.random().toString(),
      _nodeLabel.value, _nodeRelativeId.value, content.value.map { it.build() },
      positioning.value?.let { ContainerComponent.Positioning.valueOf(it) }?:
      ContainerComponent.Positioning.Relative, exactPositions.value, axis.value?.let {
      ContainerComponent.Axis.valueOf(it) }?: ContainerComponent.Axis.Y, width.value, height.value,
      layoutPriority.value?.let { ContainerComponent.LayoutPriority.valueOf(it) }?:
      ContainerComponent.LayoutPriority.LeftToRight, scrolling.value?: false,
      keepOffScreenViewsAlive.value?: false, padding.value, margin.value,
      horizontalAlignment.value?.let { ContainerComponent.HorizontalAlignment.valueOf(it) }?:
      ContainerComponent.HorizontalAlignment.Start, verticalAlignment.value?.let {
      ContainerComponent.VerticalAlignment.valueOf(it) }?: ContainerComponent.VerticalAlignment.Top,
      spacing.value?.let { ContainerComponent.Spacing.valueOf(it) }?:
      ContainerComponent.Spacing.None, spacingValue.value, backgroundType.value?.let {
      ContainerComponent.Background.valueOf(it) }?: ContainerComponent.Background.None,
      backgroundColor.value, gradient.value, backgroundImage.value, opacity.value,
      borderColor.value, borderRadius.value, borderThickness.value, shimmer.value,
      viewOpacity.value, action.value?.build(), analyticsButtonName.value, minWidth.value?: 40,
      minHeight.value?: 40, maxWidth.value, maxHeight.value, aspectRatio.value, overflow.value,
      template.value)
}
