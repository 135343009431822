package com.appcreator.compose.conversions

import com.appcreator.blueprint.conversions.BasicMathsConversion
import com.appcreator.blueprint.core.EnvStore
import kotlinx.serialization.json.JsonElement
import kotlin.math.abs
import kotlin.math.floor
import kotlin.math.pow
import kotlin.math.roundToInt

data class BasicMathsConvertor(
    val conversion: BasicMathsConversion
): Convertor {

    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String {
        with(conversion) {
            val first = lhs?.toDoubleOrNull(envStore) ?: 0.0
            val second = rhs?.toDoubleOrNull(envStore) ?: 0.0

            val result = when (maths) {
                BasicMathsConversion.Maths.Add -> first + second
                BasicMathsConversion.Maths.Subtract -> first - second
                BasicMathsConversion.Maths.Divide -> if (second == 0.0) 0.0 else first / second
                BasicMathsConversion.Maths.Multiply -> first * second
                BasicMathsConversion.Maths.Remainder -> first % second
                null -> 0.0
            }

            return result.toPrecision(precision ?: 0)
        }
    }

    private fun String.toDoubleOrNull(envStore: EnvStore): Double? {
        return envStore.injectVariables(this).toDoubleOrNull()
    }

    private fun Double.toPrecision(precision: Int) =
        if (precision < 1) {
            "${this.roundToInt()}"
        } else {
            val p = 10.0.pow(precision)
            val v = (abs(this) * p).roundToInt()
            val i = floor(v / p)
            var f = "${floor(v - (i * p)).toInt()}"
            while (f.length < precision) f = "0$f"
            val s = if (this < 0) "-" else ""
            "$s${i.toInt()}.$f"
        }

}
