package com.appcreator.compose.components.forms

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Row
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.forms.CheckboxComponent
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer
import com.appcreator.compose.extensions.value
import kotlinx.coroutines.delay

@Composable
fun CheckboxComposable(modifier: Modifier, component: CheckboxComponent) {
    val envStore = LocalEnvStore.current
    val inputEnvStore = LocalInputEnvStore.current
    val value = component.data?.value?.let { envStore.get(it) as? String } ?: ""

    val onValueChange: (String) -> Unit = { new ->
        component.data?.value?.let {
            inputEnvStore.set(it, new)
        }
    }

    val onChangeAction = component.onChangeAction?.let { Container.performer(it)?.deferred() }
    LaunchedEffect(value) {
        delay(400) // This acts as a debounce
        onChangeAction?.perform(envStore)
    }


    Row(verticalAlignment = Alignment.CenterVertically) {
        Checkbox(
            checked = value.toBooleanStrictOrNull() == true,
            onCheckedChange = {
                onValueChange(it.toString())
            }
        )
        component.label?.let {
            Text(
                modifier = Modifier.clickable {
                    val current = value.toBooleanStrictOrNull() == true
                    onValueChange(current.not().toString())
                },
                text = it.value()
            )
        }
    }
}
