@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package app_creator.app.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val logo_contentful: DrawableResource by 
      lazy { init_logo_contentful() }

  public val logo_contentful_28dp: DrawableResource by 
      lazy { init_logo_contentful_28dp() }

  public val logo_firebase: DrawableResource by 
      lazy { init_logo_firebase() }

  public val logo_firebase_28dp: DrawableResource by 
      lazy { init_logo_firebase_28dp() }

  public val logo_revenuecat: DrawableResource by 
      lazy { init_logo_revenuecat() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("logo", CommonMainDrawable0.logo)
  map.put("logo_contentful", CommonMainDrawable0.logo_contentful)
  map.put("logo_contentful_28dp", CommonMainDrawable0.logo_contentful_28dp)
  map.put("logo_firebase", CommonMainDrawable0.logo_firebase)
  map.put("logo_firebase_28dp", CommonMainDrawable0.logo_firebase_28dp)
  map.put("logo_revenuecat", CommonMainDrawable0.logo_revenuecat)
}

internal val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/app_creator.app.generated.resources/drawable/logo.xml", -1, -1),
    )
)

internal val Res.drawable.logo_contentful: DrawableResource
  get() = CommonMainDrawable0.logo_contentful

private fun init_logo_contentful(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_contentful",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/app_creator.app.generated.resources/drawable/logo_contentful.xml", -1, -1),
    )
)

internal val Res.drawable.logo_contentful_28dp: DrawableResource
  get() = CommonMainDrawable0.logo_contentful_28dp

private fun init_logo_contentful_28dp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_contentful_28dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/app_creator.app.generated.resources/drawable/logo_contentful_28dp.xml", -1, -1),
    )
)

internal val Res.drawable.logo_firebase: DrawableResource
  get() = CommonMainDrawable0.logo_firebase

private fun init_logo_firebase(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_firebase",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/app_creator.app.generated.resources/drawable/logo_firebase.xml", -1, -1),
    )
)

internal val Res.drawable.logo_firebase_28dp: DrawableResource
  get() = CommonMainDrawable0.logo_firebase_28dp

private fun init_logo_firebase_28dp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_firebase_28dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/app_creator.app.generated.resources/drawable/logo_firebase_28dp.png", -1, -1),
    )
)

internal val Res.drawable.logo_revenuecat: DrawableResource
  get() = CommonMainDrawable0.logo_revenuecat

private fun init_logo_revenuecat(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_revenuecat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/app_creator.app.generated.resources/drawable/logo_revenuecat.svg", -1, -1),
    )
)
