package com.appcreator.creatorapp.project.configurations

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import com.appcreator.blueprint.revenuecat.RevenuecatConsts
import com.appcreator.components.layouts.CheckboxGroup
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.Organisation
import com.appcreator.dto.Project
import com.appcreator.dto.configurations.RevenuecatConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfigurationTypes
import com.appcreator.styles.ThemeMargins
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonObject

private fun ThirdPartyConfiguration.configOrDefault(json: Json): RevenuecatConfiguration {
    return config?.let { json.decodeFromJsonElement(it) } ?: RevenuecatConfiguration(
        androidApiKey = "",
        iosApiKey = ""
    )
}

@Composable
fun RevenuecatConfiguration(
    organisation: Organisation,
    project: Project,
    inset: Dp = ThemeMargins.LargeMargins,
    onSaved: (RevenuecatConfiguration) -> Unit = {}
) {
    LoadingComposable(loader = {
        mutableStateOf(it.getThirdPartyConfiguration(organisation.id, project.id, ThirdPartyConfigurationTypes.revenuecat))
    }) {

        val json = LocalApiClient.current.json
        var display by remember { mutableStateOf(it.value) }
        val startConfig = remember { it.value.configOrDefault(json) }
        var displayConfig by remember { mutableStateOf(startConfig) }

        val saveButton: @Composable () -> Unit = {
            val client = LocalApiClient.current
            LoadingButton(
                label = "Save Changes",
                enabled = it.value != display || startConfig != displayConfig,
                action = {
                    client.setThirdPartyConfiguration(
                        organisation.id,
                        project.id,
                        display.copy(config = json.encodeToJsonElement(displayConfig).jsonObject)
                    )
                    it.value = display
                    onSaved(displayConfig)
                }
            )
        }

        Column(Modifier.padding(horizontal = inset)) {
            CheckboxGroup(
                label = "Enabled",
                checked = display.enabled,
                onCheckedChange = {
                    display =
                        display.copy(enabled = it, components = listOf(RevenuecatConsts.group))
                }
            )

            saveButton()

        }

        if (display.enabled) {
            Column(Modifier.padding(vertical = ThemeMargins.LargeMargins, horizontal = inset)) {
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Android API Key",
                    value = displayConfig.androidApiKey,
                    onValueChange = {
                        displayConfig = displayConfig.copy(androidApiKey = it)
                    }
                )

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "iOS Api Key",
                    value = displayConfig.iosApiKey,
                    onValueChange = {
                        displayConfig = displayConfig.copy(iosApiKey = it)
                    }
                )
            }
        }
    }
}

