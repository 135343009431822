package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.conversions.BasicMathsConversionBuilder
import com.appcreator.blueprint.conversions.DateAdditionConversionBuilder
import com.appcreator.blueprint.conversions.DateFormatConversionBuilder
import com.appcreator.blueprint.conversions.JavascriptConversionBuilder
import com.appcreator.blueprint.conversions.ListCountConversionBuilder
import com.appcreator.blueprint.conversions.NowConversionBuilder
import com.appcreator.blueprint.conversions.TodayConversionBuilder
import com.appcreator.blueprint.conversions.UUIDConversionBuilder
import com.appcreator.blueprint.conversions.UppercaseConversionBuilder
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingGetTokenConversionBuilder
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigConversionBuilder
import com.appcreator.blueprint.spec.ConversionBuilderProvider
import com.appcreator.blueprint.spec.ConversionSpec
import kotlin.collections.Map

public fun conversionOptions(): Map<ConversionSpec, ConversionBuilderProvider> = mapOf(
        ConversionSpec(
    group = "",
    name = "BasicMathsConversion",
    title = "Basic Maths",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = false,
) to ConversionBuilderProvider { BasicMathsConversionBuilder(null) },
        ConversionSpec(
    group = "",
    name = "DateAdditionConversion",
    title = "Add time",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = false,
) to ConversionBuilderProvider { DateAdditionConversionBuilder(null) },
        ConversionSpec(
    group = "",
    name = "DateFormatConversion",
    title = "Date Formatter",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = false,
) to ConversionBuilderProvider { DateFormatConversionBuilder(null) },
        ConversionSpec(
    group = "",
    name = "JavascriptConversion",
    title = "Javascript",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = false,
) to ConversionBuilderProvider { JavascriptConversionBuilder(null) },
        ConversionSpec(
    group = "",
    name = "ListCountConversion",
    title = "List Count",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = false,
) to ConversionBuilderProvider { ListCountConversionBuilder(null) },
        ConversionSpec(
    group = "",
    name = "NowConversion",
    title = "Time now",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = false,
) to ConversionBuilderProvider { NowConversionBuilder(null) },
        ConversionSpec(
    group = "",
    name = "TodayConversion",
    title = "Today",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = false,
) to ConversionBuilderProvider { TodayConversionBuilder(null) },
        ConversionSpec(
    group = "",
    name = "UUIDConversion",
    title = "Random UUID",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = false,
) to ConversionBuilderProvider { UUIDConversionBuilder(null) },
        ConversionSpec(
    group = "",
    name = "UppercaseConversion",
    title = "Uppercase",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = false,
) to ConversionBuilderProvider { UppercaseConversionBuilder(null) },
        ConversionSpec(
    group = "Firebase Messaging",
    name = "FirebaseMessagingGetTokenConversion",
    title = "Get FCM Token",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = true,
) to ConversionBuilderProvider { FirebaseMessagingGetTokenConversionBuilder(null) },
        ConversionSpec(
    group = "Firebase Remote Config",
    name = "FirebaseRemoteConfigConversion",
    title = "Firebase Remote Config",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    isExtraPlugin = true,
) to ConversionBuilderProvider { FirebaseRemoteConfigConversionBuilder(null) }
    )
