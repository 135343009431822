package com.appcreator.compose.components.data

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Warning
import androidx.compose.material3.Button
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import io.ktor.client.network.sockets.ConnectTimeoutException
import io.ktor.client.network.sockets.SocketTimeoutException
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.HttpRequestTimeoutException
import kotlinx.io.IOException

@Composable
fun DefaultLoadingComposable(modifier: Modifier) {
    Box(modifier = modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
        CircularProgressIndicator()
    }
}

@Composable
fun DefaultErrorComposable(modifier: Modifier, throwable: Throwable, retry: () -> Unit) {
    Box(modifier = modifier.padding(32.dp)) {
        Column(verticalArrangement = Arrangement.spacedBy(24.dp)) {
            val params = remember { throwable.toExceptionParams() }
            Icon(
                modifier = Modifier.size(50.dp),
                imageVector = Icons.Default.Warning,
                tint = MaterialTheme.colorScheme.onSurface,
                contentDescription = null
            )
            Text(
                text = params.title,
                color = MaterialTheme.colorScheme.onSurface,
                style = MaterialTheme.typography.headlineMedium
            )
            Text(
                text = params.message,
                color = MaterialTheme.colorScheme.onSurface,
                style = MaterialTheme.typography.bodyLarge
            )
            Button(onClick = retry) {
                Text("Retry")
            }
        }
    }
}


data class ErrorViewParams(
    val title: String,
    val message: String
)

fun Throwable.toExceptionParams(): ErrorViewParams {
    return when (this) {
        is HttpRequestTimeoutException,
        is ConnectTimeoutException,
        is SocketTimeoutException -> ErrorViewParams(
            title = "Connection timed out",
            message = "Please check your internet connection and try again."
        )
        is ClientRequestException -> ErrorViewParams(
            title = "Bad request",
            message = "Could not complete this request: ${response.status}"
        )
        is IOException -> ErrorViewParams(
            title = "Could not connect",
            message = "Please check your internet connection and try again."
        )
        else -> ErrorViewParams(
            title = "Oops something went wrong",
            message = "Please try again later."
        )
    }
}
