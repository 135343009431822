package com.appcreator.blueprint.components.forms

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.properties.LocalizableString
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Checkbox")
@ComponentClass(
    group = "Forms",
    title = "Checkbox",
    availableFrom = "0.0.1"
)
data class CheckboxComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Label",
        availableFrom = "0.0.1"
    )
    val label: LocalizableString?,

    @InputProperty(
        title = "Data Value",
        availableFrom = "0.0.1",
        description = "Value to bind use input to, this value should be created with a Local State component",
        allowDataFields = false,
        settableDataOnly = true
    )
    val data: DataPath?,

    @InputProperty(
        title = "On Change Action",
        description = "This action will be performed everytime the value changes",
        availableFrom = "0.0.1"
    )
    val onChangeAction: Action?,

): Component
