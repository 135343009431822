package com.appcreator.compose.components.basic

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.basic.VideoComponent
import com.appcreator.compose.HtmlView
import com.appcreator.compose.extensions.value
import org.w3c.dom.HTMLElement

@Composable
actual fun VideoComposable(
    modifier: Modifier,
    component: VideoComponent
) {

    component.url?.value() ?.let {
        Box(modifier.fillMaxSize().padding(32.dp)) {
            Text("Video is currently unsupported on web platform")
        }
    }
}
