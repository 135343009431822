package com.appcreator.blueprint.revenuecat

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("RevenuecatHasEntitlementCondition")
@ConditionClass(
    group = RevenuecatConsts.group,
    title = "Has Entitlement",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
data class RevenuecatHasEntitlementCondition(
    @InputProperty(
        title = "Entitlement",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    val entitlement: String?
): Condition