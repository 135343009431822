package com.appcreator.blueprint.firebase.storage

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.DataPath
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
@SerialName("FirebaseStorageUploadImageAction")
@ActionClass(
    group = FirebaseStorageConsts.group,
    title = "Upload Image/Video",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
class FirebaseStorageUploadImageAction(
    @InputProperty(
        title = "File Name",
        availableFrom = "0.0.1"
    )
    val imageName: String?,

    @InputProperty(
        title = "Allow Images",
        availableFrom = "0.0.1",
    )
    val allowImages: Boolean?,

    @InputProperty(
        title = "Allow Videos",
        availableFrom = "0.0.1",
    )
    val allowVideos: Boolean?,

    @InputProperty(
        title = "Set data with upload progress",
        availableFrom = "0.0.1",
        settableDataOnly = true
    )
    val progressOutput: DataPath?,

    @InputProperty(
        title = "Set data with URL",
        availableFrom = "0.0.1",
        settableDataOnly = true
    )
    val urlOutput: DataPath?,
): Action
