package com.appcreator.blueprint.components.data

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.LoaderConfig
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.spec.inputs.DataSpecType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("PagedLoader")
@ComponentClass(
    group = "Data",
    title = "Paging Data Loader",
    availableFrom = "0.0.1",
)
data class PagedLoaderComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Loader",
        availableFrom = "0.0.1"
    )
    val config: LoaderConfig?,

    @InputProperty(
        title = "Path",
        availableFrom = "0.0.1",
        dataOptions = DataSpecType.List,
        includeSiblingData = true
    )
    val itemsPath: DataPath?,

    @InputProperty(
        title = "Load More Condition",
        availableFrom = "0.0.1",
        includeSiblingData = true
    )
    @DisplayIf("config.value != null")
    @DisplayIf("config.value?.supportsPaging != true")
    val condition: Condition?,

    @InputProperty(
        title = "Header",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val header: Component?,

    @InputProperty(
        title = "Component",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

    @InputProperty(
        title = "Empty",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val empty: Component?,

    @InputProperty(
        title = "Footer",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val footer: Component?,

    @InputProperty(
        title = "Item Width",
        description = "Max width of item, will fit at many items in a grid as possible, leave blank for full width.",
        availableFrom = "0.0.1"
    )
    val itemWidth: Int?,

    @InputProperty(
        title = "Item Spacing",
        availableFrom = "0.0.1"
    )
    val itemSpacing: Int?,

    @InputProperty(
        section = "Advanced",
        title = "Pull to refresh",
        availableFrom = "0.0.1"
    )
    val pullToRefresh: Boolean?,

    @InputProperty(
        section = "Advanced",
        title = "Error type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("PagedLoaderComponent.ViewType.Default")
    val errorView: ViewType?,

    @InputProperty(
        section = "Advanced",
        title = "Error content",
        availableFrom = "0.0.1"
    )
    @DisplayIf("errorView.value == PagedLoaderComponent.ViewType.Custom.name")
    @AlwaysContainer
    val errorContent: Component?,

    @InputProperty(
        section = "Advanced",
        title = "Loader type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("PagedLoaderComponent.ViewType.Default")
    val loadingView: ViewType?,

    @InputProperty(
        section = "Advanced",
        title = "Loader content",
        availableFrom = "0.0.1"
    )
    @DisplayIf("loadingView.value == PagedLoaderComponent.ViewType.Custom.name")
    @AlwaysContainer
    val loadingContent: Component?,

    @InputProperty(
        section = "Advanced",
        title = "Reload From Trigger",
        availableFrom = "0.0.1"
    )
    val reloadTrigger: String?,

    @InputProperty(
        section = "Preview",
        title = "Show In Preview",
        availableFrom = "0.0.1"
    )
    @GenericDefault("PagedLoaderComponent.PreviewState.Loaded")
    val previewDisplay: PreviewState?
): Component {

    enum class ViewType {
        Default,
        Nothing,
        Custom
    }

    enum class PreviewState {
        Loading,
        Loaded,
        Error,
        Empty
    }

}
